import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'

import { PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { withStores } from 'utils/helpers.utils'

import { EloModal, EloModalFooter, EloModalBody, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'

import ProductOverview from './ProductOverview'
import TransfersOverview from './TransfersOverview'
import UpgradeTicketsOption from './UpgradeTicketsOption'

const PaymentSuccess = (props) => {
  const {
    order,
    viewType,
    classes,
    isGift,
    payerDataFromDeal,
    ownerDataFromDeal,
    hasDifferentBillingAddress,
    lastTransfer,
    showTransactionDetails,
    previewMode,
    block,
    countriesStore,
    contentPageStore,
  } = props

  useEffect(() => {
    if (!countriesStore.list.length) {
      countriesStore.fetchList()
    }
  }, [])

  const [upgradeTicketModalIsOpen, setUpgradeTicketModalIsOpen] = useState(false)

  const { sellables, provider } = order || {}

  return (
    <Fragment>
      <div>
        <div className={classes.section}>
          <div
            className={classNames(classes.sectionTitle, 'higlight-labels')}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.labels,
              'data-highlighter-selector': '.higlight-labels',
              'data-highlighter-parent': '.thank-you-block',
            })}
          >
            {I18n.t('react.shared.purchased_products')}
          </div>
        </div>

        {sellables?.map((sellable, index) => (
          <ProductOverview
            key={sellable.id}
            {...{
              sellable,
              classes,
              index,
              sellables,
              previewMode,
              viewType,
              isGift,
              order,
              block,
            }}
          />
        ))}

        <hr className={classes.lightSeparator} />

        {false && (
          <UpgradeTicketsOption
            {...{
              classes,
              setUpgradeTicketModalIsOpen,
            }}
          />
        )}

        {upgradeTicketModalIsOpen && (
          <EloModal isOpen={upgradeTicketModalIsOpen} onClose={() => setUpgradeTicketModalIsOpen(false)} size='large'>
            <EloModalHeader>
              <h1 style={{ font: 'var(--elo-heading-h3)' }}>Custom Modal</h1>
              <p style={{ font: 'var(--elo-body-sm-medium)' }}>Header</p>
            </EloModalHeader>
            <EloModalBody>
              <div style={{ font: 'var(--elo-body-sm-regular)', marginBottom: '24px' }}>Body</div>
            </EloModalBody>
            <EloModalFooter>
              <EloButton
                onClick={() => {
                  setUpgradeTicketModalIsOpen(false)
                }}
              >
                Cancel
              </EloButton>
              <EloButton>Continue to payment</EloButton>
            </EloModalFooter>
          </EloModal>
        )}

        <hr className={classes.lightSeparator} />

        <TransfersOverview
          {...{
            order,
            classes,
            showTransactionDetails,
            isGift,
            payerDataFromDeal,
            ownerDataFromDeal,
            hasDifferentBillingAddress,
            viewType,
            lastTransfer,
            previewMode,
          }}
          contentPageStore={contentPageStore}
        />
      </div>

      {/* lemonway info */}
      {provider === PAYMENT_PROVIDERS.lemonWay && showTransactionDetails && (
        <div className={classes.section}>
          <div
            dangerouslySetInnerHTML={{
              __html: I18n.t('react.shared.payment.success.provider', { name: 'elopage.com' }),
            }}
          />
        </div>
      )}

      {/* addional product info */}
      {order && order.product && order.product.additionalInfo && (
        <Fragment>
          <hr className={classes.lightSeparator} />
          <div className='additional-info fr-view'>
            <div dangerouslySetInnerHTML={{ __html: order.product.additionalInfo }} />
            <div className='clearfix' />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withStores(['countriesStore'], PaymentSuccess)
