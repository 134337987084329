import queryString from 'query-string'
import { toJS } from 'mobx'
import { isEmpty } from '@elo-kit/utils/validators.utils'

import { PURPOSES_KEYS, REDIRECTION_KEYS, FORM_KEYS, EMBED_TYPES } from 'constants/embeddableItemsShared.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { deepObjectPropNamesToSnakeCase } from './nameStyle.utils'

const webHost = getElopageConfig('webHost')

const retrieveParams = (embeddableItem) => {
  const { form, params, embedPrefs, embedPrefs: { purpose } = {}, affiliateNode } = embeddableItem || {}
  const isEmbedPaymentPage = form === FORM_KEYS.embedPaymentPage
  const isCourseLesson = purpose === PURPOSES_KEYS.courseLesson
  const publisherParams = {}
  if (affiliateNode) {
    publisherParams.pid = affiliateNode.publisherId
    publisherParams.prid = affiliateNode.affiliateProgramId
  }
  if (isEmbedPaymentPage) {
    return deepObjectPropNamesToSnakeCase({
      ...params,
      ...publisherParams,
      embedType: EMBED_TYPES.onPage,
    })
  }
  if (isCourseLesson) {
    return deepObjectPropNamesToSnakeCase({
      ...params,
      ...publisherParams,
      lessonId: embedPrefs.lessonId,
    })
  }
  return deepObjectPropNamesToSnakeCase({
    ...params,
    ...publisherParams,
  })
}

export const getLinkByParams = (embeddableItem, product = {}, userSeller = {}, isEmbed = false) => {
  const { embedPrefs, seller = {} } = embeddableItem || {}
  const { slug } = product || {}
  const { protocol, host, username } = isEmpty(seller) ? userSeller : seller
  const { purpose, redirection, transparent } = embedPrefs || {}
  const { displayed_plans_id, bundles, upsells, preselect_upsells, tickets, ...rest } = retrieveParams(
    toJS(embeddableItem)
  )
  const searchQuery = queryString.stringify({
    displayed_plans_id: Array(displayed_plans_id),
    bundles: Array(bundles),
    upsells: Array(upsells),
    preselect_upsells: Array(preselect_upsells),
    tickets: Array(JSON.stringify(tickets)),
    transparent,
    ...rest,
  })
  const userHost = isEmbed ? webHost : host
  const baseUrl = `${protocol}://${userHost}`
  const isPaymentPage = redirection === REDIRECTION_KEYS.paymentPage
  switch (purpose) {
    case PURPOSES_KEYS.product:
      return [`${baseUrl}/s/${username}/${slug}`, isPaymentPage ? '/payment' : '', '?', searchQuery].join('')
    case PURPOSES_KEYS.shop:
      return [`${baseUrl}/s/${username}`, searchQuery].join('?')
    case PURPOSES_KEYS.courseLesson:
      return [`${baseUrl}/payer/s/${username}/courses/${slug}`, searchQuery].join('?')
    case PURPOSES_KEYS.loginPage:
      return [`${baseUrl}/s/${username}/sign_in`, searchQuery].join('?')
    case PURPOSES_KEYS.customLink:
      return [`${embedPrefs.url}`, searchQuery].join(embedPrefs.url.indexOf('?') > -1 ? '&' : '?')
    default:
      return baseUrl
  }
}

export const getScript = (embeddableItem) => {
  const { token, product, form } = embeddableItem
  const webHost = getElopageConfig('webHost')
  const webProtocol = getElopageConfig('webProtocol')
  const isPlainLink = form === FORM_KEYS.plainLink
  if (isPlainLink) {
    return getLinkByParams(embeddableItem, product)
  }
  return `<div id='elopage-widgets-container' data-token='${token}'></div><script data-token='${token}' id='elopage-widgets-script' src='${webProtocol}://${webHost}/widgets.js'></script>`.trim()
}

export const getUrlToChange = (formData = {}) => {
  const { embedPrefs, form } = formData
  const { transparent } = embedPrefs
  const embedPaymentPage =
    form === FORM_KEYS.embedPaymentPage
      ? {
          embed_type: EMBED_TYPES.onPage,
          transparent,
        }
      : {}

  return formData.url
    ? `${queryString.parseUrl(formData.url).url}?${queryString.stringify({
        ...formData.params,
        ...embedPaymentPage,
      })}`
    : getLinkByParams(formData, formData.product)
}
