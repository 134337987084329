import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import classNames from 'classnames'

import { IMAGE_STYLE_TYPES } from 'shared/components/page-builder/constants/block.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'

const Button = (props) => {
  const { classes, buttonText, buttonAnimation, previewMode, defaultButtonText, buttonLink } = props
  const buttonClasses = classNames(classes.button, {
    [classes.transparency]: !buttonAnimation,
    [classes[buttonAnimation]]: !!buttonAnimation,
  })

  const handleClick = !previewMode
    ? () => {
        window.location.href = buttonLink
      }
    : () => {}

  return (
    <div
      className={classNames(buttonClasses)} //MARKETING_TOOLS_CLASSNAME.btnContainerClassName will be removed in improvement (because of custom css)
      onClick={handleClick}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.modalCta,
        'data-highlighter-selector': '',
      })}
    >
      {buttonText || defaultButtonText}
    </div>
  )
}

const ModalWindow = (props) => {
  const {
    block: {
      content: {
        titleText,
        additionalText,
        openModalAfter,
        buttonText,
        buttonLink,
        buttonAnimation,
        showButton,
        imageStyle: contentImageStyle,
      } = {},
      cover: { file: { original } = {} } = {},
    } = {},
    classes,
    previewMode,
  } = props
  const [isModalOpened, setIsModalOpened] = useState(false)
  const toggleIsModalOpened = () => setIsModalOpened(!isModalOpened)
  const imageStyle = contentImageStyle ?? IMAGE_STYLE_TYPES.smallImage
  const [isBackgroundStyle, isSmallImageStyle] = Object.values(IMAGE_STYLE_TYPES).map((item) => item === imageStyle)
  const noImageIconClasses = classNames('far fa-image', {
    [classes.noImageIcon]: isBackgroundStyle,
    [classes.smallImageIcon]: isSmallImageStyle,
  })
  const onClose = previewMode ? () => {} : toggleIsModalOpened

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpened(true)
    }, Number(openModalAfter) * 1000)
    return () => clearTimeout(timer)
  }, [])

  const image = (
    <div
      className={classNames({
        [classes.smallImageContainer]: isSmallImageStyle && previewMode && !original,
      })}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.modalBodyBackground,
        'data-highlighter-selector': '',
      })}
    >
      {original
        ? isSmallImageStyle && <img src={original} alt='modal-img' />
        : previewMode && <i className={noImageIconClasses} />}
    </div>
  )

  const modalBody = (
    <div
      className={classNames(classes.modalBody, {
        [classes.previewModalBody]: previewMode,
      })}
    >
      <div className={classes.containerOverlay} />
      <i className={classNames(classes.closeIcon, 'fas fa-times')} onClick={onClose} />
      <div className={classes.infoContainer}>
        {titleText && (
          <div
            className={classNames(classes.mainText)}
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.modalMainText,
              'data-highlighter-selector': '',
            })}
          >
            <EditTextArea value={titleText} dataKey='titleText' previewMode={previewMode}>
              {titleText}
            </EditTextArea>
          </div>
        )}
        {additionalText && (
          <EditTextArea value={additionalText} dataKey='additionalText' previewMode={previewMode}>
            <div
              className={classNames(classes.additionalText)}
              {...(previewMode && {
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.conversionTools.modalAdditionalText,
                'data-highlighter-selector': '',
              })}
            >
              {additionalText}
            </div>
          </EditTextArea>
        )}
        {showButton && (
          <Button
            {...{
              buttonAnimation,
              buttonLink,
              buttonText,
              classes,
              previewMode,
            }}
          />
        )}
      </div>
      {image}
    </div>
  )

  return previewMode ? (
    modalBody
  ) : (
    <Modal isOpen={isModalOpened} toggle={toggleIsModalOpened} title={false} cancel={false} className={classes.modal}>
      {modalBody}
    </Modal>
  )
}

ModalWindow.propTypes = {
  /** React JSS classes */
  classes: PropTypes.shape({}),
  /** Block */
  block: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** Preview mode in page builder */
  previewMode: PropTypes.bool,
}

export default ModalWindow
