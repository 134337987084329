import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { THEME_TYPE } from 'constants/general.constants'
import { BUILDER_FIELD_TYPES } from 'shared/components/page-builder/constants/pageBuilder.constants'
import { BLOCKS_WITH_COLOR_PICKER, YES_NO_OPTIONS } from '../../../../page-builder/constants/block.constants'

export default () => [
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.header.logo,
    label: I18n.t('react.shared.logo'),
    fields: ['uploadBackgroundImage'],
  },
  {
    label: I18n.t('react.shared.background'),
    fields: [BLOCKS_WITH_COLOR_PICKER.backgroundColor],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.header.menuItems,
    label: I18n.t('react.cabinet.menu_items'),
    fields: ['addHeaderMenu'],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.header.menuItems,
    label: I18n.t('react.cabinet.menu_style'),
    tooltipId: 'menu_style',
    fields: [
      [
        {
          field: 'font',
          key: 'menuFont',
        },
        {
          field: 'fontStyle',
          key: 'menuStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.menuColor,
        },
        {
          field: 'fontSize',
          key: 'menuSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.header.myAccount,
    label: I18n.t('react.shared.my_account'),
    tooltipId: 'my_account',
    fields: [
      [
        {
          field: 'font',
          key: 'accountFont',
        },
        {
          field: 'fontStyle',
          key: 'accountStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.accountColor,
        },
        {
          field: 'fontSize',
          key: 'accountSize',
        },
      ],
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.iconColor,
        label: I18n.t('react.cabinet.icon_color'),
      },
      {
        field: 'showCancelSubscription',
        key: 'showCancelSubscription',
        tooltipIdCheck: 'show_cancel_subscription',
        label: I18n.t('react.cabinet.themes.header_edit.show_cancel_subscription'),
        props: {
          defaultValue: true,
        },
      },
    ],
  },
  {
    dataMenuHighlight: 'language_switcher',
    label: I18n.t('react.shared.language_switcher'),
    toggle: 'languageSwitcher',
    tooltipId: 'language_switcher',
    show: {
      theme: THEME_TYPE.shop,
    },
    fields: [
      [
        {
          field: 'font',
          key: 'languageFont',
        },
        {
          field: 'fontStyle',
          key: 'languageStyle',
        },
      ],
      [
        {
          field: 'color',
          key: BLOCKS_WITH_COLOR_PICKER.languageColor,
        },
        {
          field: 'fontSize',
          key: 'languageSize',
        },
      ],
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.header.sharingOptions,
    label: I18n.t('react.cabinet.shop_themes.sharing_options'),
    toggle: 'sharingOptions',
    tooltipId: 'sharing_options',
    show: {
      theme: THEME_TYPE.shop,
    },
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.sharingOptionsColor,
      },
    ],
  },
  {
    dataMenuHighlight: BLOCK_MENU_HIGHLIGHT_IDS.header.websiteAndSocials,
    label: I18n.t('react.shared.website_and_socials'),
    fields: [
      {
        field: 'color',
        key: BLOCKS_WITH_COLOR_PICKER.socialsColor,
      },
      {
        field: 'link',
        key: 'website',
        label: I18n.t('react.cabinet.website'),
      },
      {
        field: 'link',
        key: 'facebook',
        label: I18n.t('react.cabinet.facebook'),
      },
      {
        field: 'link',
        key: 'instagram',
        label: I18n.t('react.cabinet.instagram'),
      },
      {
        field: 'link',
        key: 'twitter',
        label: I18n.t('react.cabinet.twitter'),
      },
      {
        field: 'link',
        key: 'youtube',
        label: I18n.t('react.cabinet.youtube'),
      },
      {
        field: 'link',
        key: 'tumblr',
        label: I18n.t('react.cabinet.tumblr'),
      },
      {
        field: 'link',
        key: 'pinterest',
        label: I18n.t('react.cabinet.pinterest'),
      },
      {
        field: 'link',
        key: 'linkedin',
        label: I18n.t('react.cabinet.linkedin'),
      },
      {
        field: 'link',
        key: 'xing',
        label: I18n.t('react.cabinet.xing'),
      },
    ],
  },
  {
    label: I18n.t('react.shared.sticky_header'),
    tooltipId: 'sticky_header',
    fields: [
      {
        field: BUILDER_FIELD_TYPES.selectBooleanItems,
        key: 'stickyHeader',
        label: I18n.t('react.shared.sticky_header'),
        props: {
          options: YES_NO_OPTIONS,
        },
      },
    ],
  },
  {
    label: I18n.t('react.shared.custom_css'),
    tooltipId: 'custom_css',
    fields: [
      {
        field: 'className',
        key: CUSTOM_CLASS_NAME_OPTION,
        label: I18n.t('react.shared.block_class_name'),
      },
    ],
  },
]
