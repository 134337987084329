import { memo } from 'react'

import TextPreview from './text/TextPreview'
import ContactFormPreview from './contact-form/ContactFormPreview'
import PicturePreview from './picture/PicturePreview'
import { VideoPreview } from './video/VideoPreview'
import FilesPreview from './files/FilesPreview'
import FilePdfPreview from './file-pdf/FilePdfPreview'
import SellerProducts from './seller-products/SellerProductsPreview'
import Testimonials from './testimonials/Testimonials'
import PopularProducts from './popular-products/PopularProducts'
import { HeaderPreview } from './header/HeaderPreview'
import { FooterPreview } from './footer/FooterPreview'
import BannerPreview from './banner/BannerPreview'
import MembershipProductsPreview from './membership-products/MembershipProductsPreview'
import ProductPreview from './product/ProductPreview'
import ButtonPreview from './button/ButtonPreview'
import FunnelPreview from './funnel/FunnelPreview'
import { AudioPlayerPreview } from './audio-player/AudioPlayerPreview'
import AffiliatePreview from './affiliate/AffiliatePreview'
import ConversionToolsPreview from './conversion-tools/ConversionToolsPreview'
import ThankYouPagePreview from './thank-you-page/ThankYouPagePreview'
import GapPreview from './gap/GapPreview'

import bannerFields from './banner/fields'
import membershipProductsFields from './membership-products/fields'
import sellerProductsFields from './seller-products/fields'
import popularProductsFields from './popular-products/fields'
import textFields from './text/fields'
import pictureFields from './picture/fields'
import filesFields from './files/fields'
import filePdfFields from './file-pdf/fields'
import videoFields from './video/fields'
import testimonialsFields from './testimonials/fields'
import headerFields from './header/fields'
import footerFields from './footer/fields'
import productFields from './product/fields'
import buttonFields from './button/fields'
import funnelFields from './funnel/fields'
import affiliateFields from './affiliate/fields'
import conversionToolsFields from './conversion-tools/fields'
import audioPlayersFields from './audio-player/fields'
import contactFormFields from './contact-form/fields'
import thankYouPageFields from './thank-you-page/fields'
import gapFields from './gap/fields'

export const BLOCKS = {
  text: memo(TextPreview),
  picture: memo(PicturePreview),
  video: VideoPreview,
  file: FilesPreview,
  file_pdf: FilePdfPreview,
  seller_products: SellerProducts,
  testimonials: Testimonials,
  popular_products: PopularProducts,
  header: HeaderPreview,
  banner: memo(BannerPreview),
  footer: FooterPreview,
  membership_products: MembershipProductsPreview,
  product: ProductPreview,
  button: ButtonPreview,
  funnel: FunnelPreview,
  audio_player: AudioPlayerPreview,
  affiliate: AffiliatePreview,
  contact_form: ContactFormPreview,
  conversion_tools: ConversionToolsPreview,
  thank_you_page: ThankYouPagePreview,
  gap: GapPreview,
}

export const BLOCKS_FIELDS = {
  banner: bannerFields,
  membership_products: membershipProductsFields,
  seller_products: sellerProductsFields,
  popular_products: popularProductsFields,
  text: textFields,
  picture: pictureFields,
  file: filesFields,
  file_pdf: filePdfFields,
  video: videoFields,
  testimonials: testimonialsFields,
  header: headerFields,
  footer: footerFields,
  product: productFields,
  button: buttonFields,
  funnel: funnelFields,
  affiliate: affiliateFields,
  conversion_tools: conversionToolsFields,
  audio_player: audioPlayersFields,
  contact_form: contactFormFields,
  thank_you_page: thankYouPageFields,
  gap: gapFields,
}
