import React from 'react'
import { EloButton } from '@elo-ui/components/elo-button'

const UpgradeTicketsOption = ({ classes, setUpgradeTicketModalIsOpen }) => (
  <div className={classes.upgradeTicketsSection}>
    <div className={classes.upgradeTicketsText}>
      <div className={classes.upgradeTicketsLabel}>Upgrade your tickets now!</div>
      <div className={classes.upgradeTicketsDescription}>Enjoy more benefits by upgrading your tickets.</div>
    </div>
    <EloButton
      variant='highlight'
      size='large'
      style={{ height: '48px' }}
      onClick={() => setUpgradeTicketModalIsOpen(true)}
    >
      {I18n.t('react.shared.upgrade')}
    </EloButton>
  </div>
)

export default UpgradeTicketsOption
